<template>
  <div class="edit_article">
    <div style="font-size: 30px;text-align: center;">编辑文章</div>
    <NewForm ref="NewForm" :formSettingsData="form" :data="formData" :optionData="optionData" imgProp="annex"></NewForm>
    <editor ref="editor" :content="content" @input_editor="input_editor"></editor>
    <div class="submit_wrap">
      <el-button type="primary" @click="saveFun">提交</el-button>
    </div>
  </div>
</template>

<script>
  import { get, post } from '../../request/http'
  let that
  export default {
    props: ['id'],
    data() {
      return {
        form: [{
            label: '文章标题:',
            prop: 'title',
            type: 'input',
            width: '100%',
            rulesData: 'must'
          },
          {
            label: '文章类型:',
            prop: 'type',
            type: 'select',
            width: '100%',
            rulesData: 'must'
          },
          {
            label: '来源:',
            prop: 'source',
            type: 'input',
            width: '100%'
          },
          {
            label: '转载自:',
            prop: 'reprint',
            type: 'input',
            width: '100%',
            placeholder: '请输入网址'
          },
          {
            label: '是否置顶:',
            prop: 'isTop',
            type: 'select',
            width: '100%',
            rulesData: 'must'
          },
          {
            label: '文章封面:',
            prop: 'annex',
            type: 'upload',
            width: '100%',
          },
        ],
        formData: {},
        content: '',
        optionData: {
          type: [{
              label: '软件行业',
              value: '0'
            },
            {
              label: '医药行业',
              value: '1'
            },
            {
              label: '数字经济',
              value: '2'
            }
          ],
          isTop: [{
              label: '是',
              value: '1'
            },
            {
              label: '否',
              value: '0'
            },
          ]
        }
      }
    },
    mounted () {
      that = this
      that.getArticleDetail()
    },
    methods: {
      getArticleDetail () {
        get('/information/getInfo?id=' + this.id).then (res => {
          if (res.code == 200) {
            that.formData = res.data
            that.content = that.formData.content
          } else {
            this.$message.warning(res.msg)
          }
        })
      },
      input_editor(html) {
        this.formData.content = html
      },
      saveFun () {
				const NewForm = this.$refs.NewForm.validateForm()
				if (!NewForm) {
					this.$message.warning('请检查信息与格式是否填写完整！')
					return 0
				}
        if (this.formData.content == '') {
          this.$message.warning('请完整填写文章！')
					return
        }
        if (!this.formData.content) {
          this.$message.warning('请完整填写文章！')
					return
        }
        post('/information/updateInfo', this.formData).then( res => {
          if (res.code == 200) {
            that.$message.success(res.msg)
          } else {
            that.$message.warning(res.msg)
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.NewForm {
  margin-top: 20px;
}
.submit_wrap {
  text-align: center;
  margin: 20px;
}
</style>
<style lang="less">
.edit_article .el-form-item__content {
  width: calc(100% - 75px);
}
.edit_article .el-select {
  width: 100%;
}
.edit_article .el-input--suffix {
  width: 100%;
}
</style>
