<template>
    <div class="article_manage">
        <div class="header">
            <img src="../../assets/image/yunge-lan.png" @click="jumpHome" />
        </div>
        <div class="container">
            <div class="content_left">
                <el-menu :default-active="sign" class="el-menu-vertical-demo">
                    <el-menu-item index="0" @click="changeIndex('0')">
                        <i class="el-icon-setting"></i>
                        <span slot="title">资讯管理</span>
                    </el-menu-item>
                    <el-menu-item index="1" @click="changeIndex('1')">
                        <i class="el-icon-menu"></i>
                        <span slot="title">资讯发布</span>
                    </el-menu-item>
                </el-menu>
            </div>
            <div class="content_right">
                <div class="content_right_container" v-if="sign == 0">
                    <p class="type_tip">资讯管理</p>
                    <el-tabs v-model="type" class="tab_list">
                        <el-tab-pane label="软件行业" name="0"></el-tab-pane>
                        <el-tab-pane label="医疗行业" name="1"></el-tab-pane>
                        <el-tab-pane label="数字经济" name="2"></el-tab-pane>
                    </el-tabs>
                    <div class="article_list_warp">
                        <el-table :data="tableData" style="width: 100%">
                            <el-table-column prop="title" label="标题" min-width="45%"></el-table-column>
                            <el-table-column prop="annex" label="封面" min-width="10%">
                                <template slot-scope="scope">
                                    <img :src="scope.row.annex" style="width: 50px;height: 50px">
                                </template>
                            </el-table-column>
                            <el-table-column prop="infoDate" label="发表时间" min-width="20%"></el-table-column>
                            <el-table-column prop="operate" label="操作" min-width="25%">
                                <template slot-scope="scope">
                                    <button @click="jumpEdit(scope.row.id)" style="color: #2196F3;">编辑</button>
                                    <button @click="deleteArticle(scope.row.id)" style="color: #FF5722;">删除</button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="paging">
                            <el-pagination style="margin-top: 20px" background :current-page.sync="page" @current-change="currentChange" :page-size="5" layout="prev, pager, next, jumper" :total="total - 0" v-if="showPage"></el-pagination>
                            <span class="totalInfo">
                                <span>/5条，共{{ total }}条 </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="sign == 1">
                    <addArticle></addArticle>
                </div>
                <div v-if="sign == 2">
                    <editArticle :id="id"></editArticle>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let that
import { get, post } from '../../request/http'
import addArticle from './add_article'
import editArticle from './edit_article'
export default {
    components: {
        addArticle,
        editArticle
    },
    data() {
        return {
            type: 0,  // 资讯分类
            tableData: [],  // 表格数据
            sign: '0',  // 资讯管理/资讯发布标识
            id: '',  // 文章编号
            page: 1,  // 当前页码
            total: 0,  // 总页码
            showPage: true  // 页面显示状态
        }
    },
    mounted() {
        if (sessionStorage.getItem('userInfo') && JSON.parse(sessionStorage.getItem('userInfo')).password == 'fzygsz2020') {
            this.$router.push({ path: '/index/articleManage' })
        } else {
            this.$router.push({ path: '/login' })
        }
        that = this
        this.sign = this.$route.query.sign || '0'
        this.type = this.$route.query.type || 0
        this.page = this.$route.query.page - 0 || 1
        this.showPage = false
        this.$nextTick(() => {
            this.showPage = true
        })
        this.getInfoList()
    },
    methods: {
        routerPush() {
            this.$router.push({
                path: '/index/articleManage',
                query: {
                    sign: this.sign,
                    type: this.type,
                    page: this.page
                }
            })
        },
        getInfoList() {
            that.tableData = []
            post('/information/getInfoList', {
                page: Number(this.page),
                pageNum: 5,
                title: '',
                type: this.type
            }).then(res => {
                if (res.code == 200) {
                    that.tableData = res.data
                    this.total = res.sum
                } else {
                    that.$message.warning(res.msg)
                }
            })
        },
        jumpEdit(id) {
            this.id = id
            this.sign = '2'
            this.routerPush()
        },
        jumpHome() {
            this.$router.push({ path: '/' })
        },
        deleteArticle(id) {
            let pass = false
            this.$confirm('确定删除当前资讯吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            })
                .then(() => {
                    pass = true
                    get('/information/delInfo', {
                        id: id
                    }).then(res => {
                        if (res.code == 200) {
                            that.$message.success(res.msg)
                            that.getInfoList()
                        } else {
                            that.$message.warning(res.msg)
                        }
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })
                })
        },
        changeIndex(index) {
            this.sign = index
            this.routerPush()
            this.getInfoList()
        },
        currentChange(page) {
            this.page = page
            this.routerPush()
            this.getInfoList()
        }
    },
    watch: {
        type() {
            this.page = 1
            this.routerPush()
            this.getInfoList()
        },
        $route() {
            this.sign = this.$route.query.sign
        }
    }
}
</script>

<style lang="less" scoped>
.header {
    height: 56px;
    background: #fff;
    img {
        height: 70%;
        position: relative;
        transform: translateY(-50%);
        top: 50%;
        padding: 0 48px;
        cursor: pointer;
    }
}
.container {
    background: #f6f7f8;
    height: 100%;
    display: flex;
    padding-top: 32px;
    .content_left {
        width: 225px;
        text-align: center;
    }
    .content_right {
        flex: .9;
        margin: auto;
        .content_right_container {
            // width: 90%;
            .type_tip {
                font-size: 25px;
                margin-bottom: 20px;
            }
            .tab_list {
                margin-bottom: 24px;
            }
            .article_list_warp {
                background: #fff;
                box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.02);
                border-radius: 10px;
                padding: 24px 40px;
                margin-bottom: 24px;
                button {
                    width: 90px;
                    height: 32px;
                    background: #fff;
                    border: 1px solid #ddd;
                    margin-right: 3px;
                    border-radius: 5px;
                    cursor: pointer;
                }
            }
        }
    }
}
.paging {
    margin: 20px 0;
    display: flex;
    align-items: center;
    /deep/ .el-pagination {
        padding: 0;
    }
    > span {
        display: inline-block;
        height: 28px;
        line-height: 29px;
        margin-top: 20px;
        color: #303133;
        font-size: 13px;
    }
}
</style>
<style>
.article_manage .el-menu {
    background: #f6f7f8;
    border-right: none;
}
.article_manage .el-table .cell {
    white-space: nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
}
</style>
